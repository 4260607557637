@import url(https://fonts.googleapis.com/css?family=Roboto:300i,400,400i,500,700,900);
@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);

.inner-quickview img.catgeory-img-index{
    border-radius: 50%;
}
img.view-all-cats {
    width: 73%;
}

img.catgeory-img-index {
    border-radius: 0;
}

.carousel-caption {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.carousel-caption .caption-head,
.carousel-caption .caption-desc {
    text-shadow: 1px 1px #000000;
}

.slick-dots {
    position: absolute;
    bottom: 15%;
    left: -45%;
}

.slick-dots li {
    background-color: #ffffff;
    height: 1.2rem;
    width: 0.37rem;
    transition-duration: 0.5s;
}

.slick-dots li.slick-active {
    color: #ffffff;
    height: 1.8rem;
    background-color: rgb(8, 255, 140);
}

.slick-dots li button::before {
    content: none;
}

/* Carousel Arrows */

.main-slide .carousel-nav-btn {
    background-color: #282d3b;
    padding: 1rem 0.7rem;
    border: none;
    width: -webkit-max-content;
    width: max-content;
}

.main-slide .slick-arrow {
    display: block;
    position: absolute;
    top: 50%;
    font-size: 2rem;
    height: 20px;
    width: 20px;
    z-index: 99;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.main-slide .next-arrow {
    left: 1rem;
    display: block;
    cursor: pointer;
    color: #ffffff;
}
.main-slide .prev-arrow {
    right: 1rem;
    display: block;
    cursor: pointer;
    color: #ffffff;
}

.main-slide.books-sections .next-arrow {
    left: -0.5rem;
}

.main-slide.books-sections .prev-arrow {
    right: -0.5rem;
}

@media (max-width: 992px) {
    .slick-dots {
        left: -43%;
    }
}

@media (max-width: 756px) {
    .slick-dots {
        left: -40%;
    }
}

@media (max-width: 576px) {
    .slick-dots {
        display: none !important;
    }

    .main-slide .next-arrow {
        left: 0.5rem;
    }
    .main-slide .prev-arrow {
        right: 0.5rem;
    }

    .carousel-caption {
        text-align: center;
    }

    .main-slide.books-sections .next-arrow {
        left: -0.5rem;
    }

    .main-slide.books-sections .prev-arrow {
        right: -0.5rem;
    }
}

ul.flex-header{
    display: flex;
    justify-content: space-between;
}

ul.flex-header li.view-all-btn{
    margin-top: 1.2rem;
}

ul.flex-header li.view-all-btn a{
    font-size: 1.5rem;
    font-weight: bold;
    color: #fff;
    background-color: #282D3B;
    padding: 0.5rem 1rem;
}
ul.footer-links li {
    position: relative;
}

ul.footer-links li a::before {
    content: "⁕";
    font-weight: 700;
    position: absolute;
    font-size: 1.8rem;
    left: 0;
    top: -3px;
}

ul.footer-links li a {
    padding-left: 1.5rem;
}

ul.footer-links li a:hover {
    text-decoration: underline;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 25px;
    height: 25px;
    margin-top: -0.5rem;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    margin: 5px;
    border: 5px solid #fff;
    border-radius: 50%;
    -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    -webkit-animation-delay: -0.45s;
            animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    -webkit-animation-delay: -0.15s;
            animation-delay: -0.15s;
  }
  @-webkit-keyframes lds-ring {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes lds-ring {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
.error-img img{
    width: 20%;
}

@media (max-width: 568px) {
    .error-img img{
        width: 40%;
    }
}
.single-author:hover{
    /* box shadow */
    box-shadow: 1px 9px 18px -15px rgba(0,0,0,0.75);

}
.product-highlights{
    font-size: 1.5rem;
}

.product-desc-content ul {
    list-style-type: disc;
}

.product-highlights li {
    margin-bottom: 0.2rem;
}

.preview-modal-wrap{
    text-align: center;
}

img.image-gallery-image{
    max-width: 80% !important;
    margin: 0 auto;
}

.author-name{
    display:flex; 
    align-items: center; 
    margin-left: 1rem; 
    flex: 1 1;
}
.Overlay {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.75);
}

.pageModal {
  z-index: 999;
}

.pageModal:focus {
  outline: 0px solid transparent;
}

/* Inner Content Modal */
.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.modal-box-body {
  padding: 5rem 2rem 2rem 2rem;
  background-color: #ffffff;
}

button.closeModalBtn {
  position: absolute;
  right: 0.5rem;
  color: #000000;
  background: transparent;
  border: none;
  top: 0.8rem;
  font-size: 2.2rem;
  cursor: pointer;
}

@media (max-width: 576px) {
  button.closeModalBtn {
    right: 0.5rem;
  }
}

.react-toast-custom-css{
    margin: 1.5rem;
}
.author-details-img img {
    width: 80%;
    border-radius: 50%;
}

.author-bio ul{
    list-style-type: disc;
}

.author-desc h3{
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #ddd;
}

@media (max-width: 568px) {
    .author-details-img img {
        width: 70%;
    }

    .author-details-img{
        margin-bottom: 2rem;
    }

    .author-desc h3{
        text-align: center;
    }

}
.card .card-body{
    height: auto;
    min-height: auto;
}

.product-widget figure a img{
    width: 100px;
}

.product-widget .product-title a{
    font-size: 1.5rem;
    text-decoration: none;
}

.product-widget .product-title a:hover{
    text-decoration: underline;
}

.product-widget:last-child{
    margin-bottom: 0;
}

.adr-btns a{
    padding: 0.5rem 1rem;
}

.adr-btns a:first-child{
    margin-right: 0.5rem;
}
.table-mini-cart .product-title{
    max-width: 250px;
}

.table-mini-cart .product-col .product-image-container{
    width: 100px;
}

.order-summary h5{
    margin-bottom: 0;
    text-align: right;
}

@media(max-width: 1200px){
    .table-mini-cart .product-title{
        max-width: 200px;
    }
}

@media(max-width: 1024px){
    .table-mini-cart .product-title{
        max-width: 100px;
    }
}

@media(max-width: 768px){
    .table-mini-cart .product-title{
        max-width: 250px;
    }
}
/*font Variables*/
/*Color Variables*/

.multi_step_form {
    height: 100%;
    width: 100%;
}

.multi_step_form #msform {
    text-align: center;
    position: relative;
    margin: 0 auto;
    z-index: 1;
    height: 100px;
    width: 100%;
    margin-top: 2rem;
    overflow: hidden;
}
.multi_step_form #msform .tittle {
    text-align: center;
    padding-bottom: 55px;
}
.multi_step_form #msform .tittle h2 {
    font: 500 24px/35px "Roboto", sans-serif;
    color: #3f4553;
    padding-bottom: 5px;
}
.multi_step_form #msform .tittle p {
    font: 400 16px/28px "Roboto", sans-serif;
    color: #5f6771;
}
.multi_step_form #msform fieldset {
    border: 0;
    padding: 20px 105px 0;
    position: relative;
    width: 100%;
    left: 0;
    right: 0;
}
.multi_step_form #msform fieldset:not(:first-of-type) {
    display: none;
}
.multi_step_form #msform fieldset h3 {
    font: 500 18px/35px "Roboto", sans-serif;
    color: #3f4553;
}
.multi_step_form #msform fieldset h6 {
    font: 400 15px/28px "Roboto", sans-serif;
    color: #5f6771;
    padding-bottom: 30px;
}
.multi_step_form #msform fieldset .intl-tel-input {
    display: block;
    background: transparent;
    border: 0;
    box-shadow: none;
    outline: none;
}
.multi_step_form
    #msform
    fieldset
    .intl-tel-input
    .flag-container
    .selected-flag {
    padding: 0 20px;
    background: transparent;
    border: 0;
    box-shadow: none;
    outline: none;
    width: 65px;
}
.multi_step_form
    #msform
    fieldset
    .intl-tel-input
    .flag-container
    .selected-flag
    .iti-arrow {
    border: 0;
}
.multi_step_form
    #msform
    fieldset
    .intl-tel-input
    .flag-container
    .selected-flag
    .iti-arrow:after {
    content: "\f35f";
    position: absolute;
    top: 0;
    right: 0;
    font: normal normal normal 24px/7px Ionicons;
    color: #5f6771;
}
.multi_step_form #msform fieldset #phone {
    padding-left: 80px;
}
.multi_step_form #msform fieldset .form-group {
    padding: 0 10px;
}
.multi_step_form #msform fieldset .fg_2,
.multi_step_form #msform fieldset .fg_3 {
    padding-top: 10px;
    display: block;
    overflow: hidden;
}
.multi_step_form #msform fieldset .fg_3 {
    padding-bottom: 70px;
}
.multi_step_form #msform fieldset .form-control,
.multi_step_form #msform fieldset .product_select {
    border-radius: 3px;
    border: 1px solid #d8e1e7;
    padding: 0 20px;
    height: auto;
    font: 400 15px/48px "Roboto", sans-serif;
    color: #5f6771;
    box-shadow: none;
    outline: none;
    width: 100%;
}
.multi_step_form #msform fieldset .form-control.placeholder,
.multi_step_form #msform fieldset .product_select.placeholder {
    color: #5f6771;
}
.multi_step_form #msform fieldset .form-control:-moz-placeholder,
.multi_step_form #msform fieldset .product_select:-moz-placeholder {
    color: #5f6771;
}
.multi_step_form #msform fieldset .form-control::-moz-placeholder,
.multi_step_form #msform fieldset .product_select::-moz-placeholder {
    color: #5f6771;
}
.multi_step_form #msform fieldset .form-control::-webkit-input-placeholder,
.multi_step_form #msform fieldset .product_select::-webkit-input-placeholder {
    color: #5f6771;
}
.multi_step_form #msform fieldset .form-control:hover,
.multi_step_form #msform fieldset .form-control:focus,
.multi_step_form #msform fieldset .product_select:hover,
.multi_step_form #msform fieldset .product_select:focus {
    border-color: #5cb85c;
}
.multi_step_form #msform fieldset .form-control:focus.placeholder,
.multi_step_form #msform fieldset .product_select:focus.placeholder {
    color: transparent;
}
.multi_step_form #msform fieldset .form-control:focus:-moz-placeholder,
.multi_step_form #msform fieldset .product_select:focus:-moz-placeholder {
    color: transparent;
}
.multi_step_form #msform fieldset .form-control:focus::-moz-placeholder,
.multi_step_form #msform fieldset .product_select:focus::-moz-placeholder {
    color: transparent;
}
.multi_step_form
    #msform
    fieldset
    .form-control:focus::-webkit-input-placeholder,
.multi_step_form
    #msform
    fieldset
    .product_select:focus::-webkit-input-placeholder {
    color: transparent;
}
.multi_step_form #msform fieldset .product_select:after {
    display: none;
}
.multi_step_form #msform fieldset .product_select:before {
    content: "\f35f";
    position: absolute;
    top: 0;
    right: 20px;
    font: normal normal normal 24px/48px Ionicons;
    color: #5f6771;
}
.multi_step_form #msform fieldset .product_select .list {
    width: 100%;
}
.multi_step_form #msform fieldset .done_text {
    padding-top: 40px;
}
.multi_step_form #msform fieldset .done_text .don_icon {
    height: 36px;
    width: 36px;
    line-height: 36px;
    font-size: 22px;
    margin-bottom: 10px;
    background: #5cb85c;
    display: inline-block;
    border-radius: 50%;
    color: #ffffff;
    text-align: center;
}
.multi_step_form #msform fieldset .done_text h6 {
    line-height: 23px;
}
.multi_step_form #msform fieldset .code_group {
    margin-bottom: 60px;
}
.multi_step_form #msform fieldset .code_group .form-control {
    border: 0;
    border-bottom: 1px solid #a1a7ac;
    border-radius: 0;
    display: inline-block;
    width: 30px;
    font-size: 30px;
    color: #5f6771;
    padding: 0;
    margin-right: 7px;
    text-align: center;
    line-height: 1;
}
.multi_step_form #msform fieldset .passport {
    margin-top: -10px;
    padding-bottom: 30px;
    position: relative;
}
.multi_step_form #msform fieldset .passport .don_icon {
    height: 36px;
    width: 36px;
    line-height: 36px;
    font-size: 22px;
    position: absolute;
    top: 4px;
    right: 0;
    background: #5cb85c;
    display: inline-block;
    border-radius: 50%;
    color: #ffffff;
    text-align: center;
}
.multi_step_form #msform fieldset .passport h4 {
    font: 500 15px/23px "Roboto", sans-serif;
    color: #5f6771;
    padding: 0;
}
.multi_step_form #msform fieldset .input-group {
    padding-bottom: 40px;
}
.multi_step_form #msform fieldset .input-group .custom-file {
    width: 100%;
    height: auto;
}
.multi_step_form #msform fieldset .input-group .custom-file .custom-file-label {
    width: 168px;
    border-radius: 5px;
    cursor: pointer;
    font: 700 14px/40px "Roboto", sans-serif;
    border: 1px solid #99a2a8;
    text-align: center;
    transition: all 300ms linear 0s;
    color: #5f6771;
}
.multi_step_form
    #msform
    fieldset
    .input-group
    .custom-file
    .custom-file-label
    i {
    font-size: 20px;
    padding-right: 10px;
}
.multi_step_form
    #msform
    fieldset
    .input-group
    .custom-file
    .custom-file-label:hover,
.multi_step_form
    #msform
    fieldset
    .input-group
    .custom-file
    .custom-file-label:focus {
    background: #5cb85c;
    border-color: #5cb85c;
    color: #fff;
}
.multi_step_form #msform fieldset .input-group .custom-file input {
    display: none;
}
.multi_step_form #msform fieldset .file_added {
    text-align: left;
    padding-left: 190px;
    padding-bottom: 60px;
}
.multi_step_form #msform fieldset .file_added li {
    font: 400 15px/28px "Roboto", sans-serif;
    color: #5f6771;
}
.multi_step_form #msform fieldset .file_added li a {
    color: #5cb85c;
    font-weight: 500;
    display: inline-block;
    position: relative;
    padding-left: 15px;
}
.multi_step_form #msform fieldset .file_added li a i {
    font-size: 22px;
    padding-right: 8px;
    position: absolute;
    left: 0;
    -webkit-transform: rotate(20deg);
            transform: rotate(20deg);
}
.multi_step_form #msform #progressbar {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    height: 100%;
}

.multi_step_form #msform #progressbar.cancelled li {
    width: 100%;
}

.multi_step_form #msform #progressbar li {
    list-style-type: none;
    color: #99a2a8;
    font-size: 9px;
    width: calc(100% / 4);
    float: left;
    position: relative;
    font: 500 13px/1 "Roboto", sans-serif;
}

.multi_step_form #msform #progressbar.cancelled li:nth-child(1):before {
    content: "\f36e";
}

.multi_step_form #msform #progressbar li:nth-child(2):before {
    content: "\f120";
}
.multi_step_form #msform #progressbar li:nth-child(3):before {
    content: "\f36f";
}
.multi_step_form #msform #progressbar li:nth-child(4):before {
    content: "\f38e";
}
.multi_step_form #msform #progressbar li:nth-child(5):before {
    content: "\f12e";
}
.multi_step_form #msform #progressbar li:nth-child(6):before {
    content: "\f374";
}
.multi_step_form #msform #progressbar li:nth-child(7):before {
    content: "\f119";
}
.multi_step_form #msform #progressbar li:before {
    content: "\f370";
    font: normal normal normal 30px/50px Ionicons;
    width: 50px;
    height: 50px;
    line-height: 50px;
    display: block;
    background: #eaf0f4;
    border-radius: 50%;
    margin: 0 auto 10px auto;
}
.multi_step_form #msform #progressbar li:after {
    content: "";
    width: 100%;
    height: 10px;
    background: #eaf0f4;
    position: absolute;
    left: -50%;
    top: 21px;
    z-index: -1;
}
.multi_step_form #msform #progressbar li:last-child:after {
    width: 150%;
}
.multi_step_form #msform #progressbar li.active {
    color: #5cb85c;
}

.multi_step_form #msform #progressbar.cancelled li.active {
    color: #ff0000;
}

.multi_step_form #msform #progressbar.cancelled li.active:after,
.multi_step_form #msform #progressbar.cancelled li.active:before {
    background: #ff0000;
}

.multi_step_form #msform #progressbar li.active:before,
.multi_step_form #msform #progressbar li.active:after {
    background: #5cb85c;
    color: white;
}
.multi_step_form #msform .action-button {
    background: #5cb85c;
    color: white;
    border: 0 none;
    border-radius: 5px;
    cursor: pointer;
    min-width: 130px;
    font: 700 14px/40px "Roboto", sans-serif;
    border: 1px solid #5cb85c;
    margin: 0 5px;
    text-transform: uppercase;
    display: inline-block;
}
.multi_step_form #msform .action-button:hover,
.multi_step_form #msform .action-button:focus {
    background: #405867;
    border-color: #405867;
}
.multi_step_form #msform .previous_button {
    background: transparent;
    color: #99a2a8;
    border-color: #99a2a8;
}
.multi_step_form #msform .previous_button:hover,
.multi_step_form #msform .previous_button:focus {
    background: #405867;
    border-color: #405867;
    color: #fff;
}

@media (max-width: 576px) {
    .multi_step_form #msform {
        overflow-x: hidden;
    }

    .multi_step_form #msform #progressbar {
        margin-bottom: 0px;
        height: 100%;
        overflow-x: auto;
        width: 356px;
    }
}

ul.list-disc {
    padding-left: 2rem;
    list-style-type: disc;
}

ul.list-num {
    padding-left: 2rem;
    list-style-type: decimal;
}

