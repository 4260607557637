.card .card-body{
    height: auto;
    min-height: auto;
}

.product-widget figure a img{
    width: 100px;
}

.product-widget .product-title a{
    font-size: 1.5rem;
    text-decoration: none;
}

.product-widget .product-title a:hover{
    text-decoration: underline;
}

.product-widget:last-child{
    margin-bottom: 0;
}

.adr-btns a{
    padding: 0.5rem 1rem;
}

.adr-btns a:first-child{
    margin-right: 0.5rem;
}