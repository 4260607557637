/*font Variables*/
/*Color Variables*/
@import url("https://fonts.googleapis.com/css?family=Roboto:300i,400,400i,500,700,900");
@import url("https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css");

.multi_step_form {
    height: 100%;
    width: 100%;
}

.multi_step_form #msform {
    text-align: center;
    position: relative;
    margin: 0 auto;
    z-index: 1;
    height: 100px;
    width: 100%;
    margin-top: 2rem;
    overflow: hidden;
}
.multi_step_form #msform .tittle {
    text-align: center;
    padding-bottom: 55px;
}
.multi_step_form #msform .tittle h2 {
    font: 500 24px/35px "Roboto", sans-serif;
    color: #3f4553;
    padding-bottom: 5px;
}
.multi_step_form #msform .tittle p {
    font: 400 16px/28px "Roboto", sans-serif;
    color: #5f6771;
}
.multi_step_form #msform fieldset {
    border: 0;
    padding: 20px 105px 0;
    position: relative;
    width: 100%;
    left: 0;
    right: 0;
}
.multi_step_form #msform fieldset:not(:first-of-type) {
    display: none;
}
.multi_step_form #msform fieldset h3 {
    font: 500 18px/35px "Roboto", sans-serif;
    color: #3f4553;
}
.multi_step_form #msform fieldset h6 {
    font: 400 15px/28px "Roboto", sans-serif;
    color: #5f6771;
    padding-bottom: 30px;
}
.multi_step_form #msform fieldset .intl-tel-input {
    display: block;
    background: transparent;
    border: 0;
    box-shadow: none;
    outline: none;
}
.multi_step_form
    #msform
    fieldset
    .intl-tel-input
    .flag-container
    .selected-flag {
    padding: 0 20px;
    background: transparent;
    border: 0;
    box-shadow: none;
    outline: none;
    width: 65px;
}
.multi_step_form
    #msform
    fieldset
    .intl-tel-input
    .flag-container
    .selected-flag
    .iti-arrow {
    border: 0;
}
.multi_step_form
    #msform
    fieldset
    .intl-tel-input
    .flag-container
    .selected-flag
    .iti-arrow:after {
    content: "\f35f";
    position: absolute;
    top: 0;
    right: 0;
    font: normal normal normal 24px/7px Ionicons;
    color: #5f6771;
}
.multi_step_form #msform fieldset #phone {
    padding-left: 80px;
}
.multi_step_form #msform fieldset .form-group {
    padding: 0 10px;
}
.multi_step_form #msform fieldset .fg_2,
.multi_step_form #msform fieldset .fg_3 {
    padding-top: 10px;
    display: block;
    overflow: hidden;
}
.multi_step_form #msform fieldset .fg_3 {
    padding-bottom: 70px;
}
.multi_step_form #msform fieldset .form-control,
.multi_step_form #msform fieldset .product_select {
    border-radius: 3px;
    border: 1px solid #d8e1e7;
    padding: 0 20px;
    height: auto;
    font: 400 15px/48px "Roboto", sans-serif;
    color: #5f6771;
    box-shadow: none;
    outline: none;
    width: 100%;
}
.multi_step_form #msform fieldset .form-control.placeholder,
.multi_step_form #msform fieldset .product_select.placeholder {
    color: #5f6771;
}
.multi_step_form #msform fieldset .form-control:-moz-placeholder,
.multi_step_form #msform fieldset .product_select:-moz-placeholder {
    color: #5f6771;
}
.multi_step_form #msform fieldset .form-control::-moz-placeholder,
.multi_step_form #msform fieldset .product_select::-moz-placeholder {
    color: #5f6771;
}
.multi_step_form #msform fieldset .form-control::-webkit-input-placeholder,
.multi_step_form #msform fieldset .product_select::-webkit-input-placeholder {
    color: #5f6771;
}
.multi_step_form #msform fieldset .form-control:hover,
.multi_step_form #msform fieldset .form-control:focus,
.multi_step_form #msform fieldset .product_select:hover,
.multi_step_form #msform fieldset .product_select:focus {
    border-color: #5cb85c;
}
.multi_step_form #msform fieldset .form-control:focus.placeholder,
.multi_step_form #msform fieldset .product_select:focus.placeholder {
    color: transparent;
}
.multi_step_form #msform fieldset .form-control:focus:-moz-placeholder,
.multi_step_form #msform fieldset .product_select:focus:-moz-placeholder {
    color: transparent;
}
.multi_step_form #msform fieldset .form-control:focus::-moz-placeholder,
.multi_step_form #msform fieldset .product_select:focus::-moz-placeholder {
    color: transparent;
}
.multi_step_form
    #msform
    fieldset
    .form-control:focus::-webkit-input-placeholder,
.multi_step_form
    #msform
    fieldset
    .product_select:focus::-webkit-input-placeholder {
    color: transparent;
}
.multi_step_form #msform fieldset .product_select:after {
    display: none;
}
.multi_step_form #msform fieldset .product_select:before {
    content: "\f35f";
    position: absolute;
    top: 0;
    right: 20px;
    font: normal normal normal 24px/48px Ionicons;
    color: #5f6771;
}
.multi_step_form #msform fieldset .product_select .list {
    width: 100%;
}
.multi_step_form #msform fieldset .done_text {
    padding-top: 40px;
}
.multi_step_form #msform fieldset .done_text .don_icon {
    height: 36px;
    width: 36px;
    line-height: 36px;
    font-size: 22px;
    margin-bottom: 10px;
    background: #5cb85c;
    display: inline-block;
    border-radius: 50%;
    color: #ffffff;
    text-align: center;
}
.multi_step_form #msform fieldset .done_text h6 {
    line-height: 23px;
}
.multi_step_form #msform fieldset .code_group {
    margin-bottom: 60px;
}
.multi_step_form #msform fieldset .code_group .form-control {
    border: 0;
    border-bottom: 1px solid #a1a7ac;
    border-radius: 0;
    display: inline-block;
    width: 30px;
    font-size: 30px;
    color: #5f6771;
    padding: 0;
    margin-right: 7px;
    text-align: center;
    line-height: 1;
}
.multi_step_form #msform fieldset .passport {
    margin-top: -10px;
    padding-bottom: 30px;
    position: relative;
}
.multi_step_form #msform fieldset .passport .don_icon {
    height: 36px;
    width: 36px;
    line-height: 36px;
    font-size: 22px;
    position: absolute;
    top: 4px;
    right: 0;
    background: #5cb85c;
    display: inline-block;
    border-radius: 50%;
    color: #ffffff;
    text-align: center;
}
.multi_step_form #msform fieldset .passport h4 {
    font: 500 15px/23px "Roboto", sans-serif;
    color: #5f6771;
    padding: 0;
}
.multi_step_form #msform fieldset .input-group {
    padding-bottom: 40px;
}
.multi_step_form #msform fieldset .input-group .custom-file {
    width: 100%;
    height: auto;
}
.multi_step_form #msform fieldset .input-group .custom-file .custom-file-label {
    width: 168px;
    border-radius: 5px;
    cursor: pointer;
    font: 700 14px/40px "Roboto", sans-serif;
    border: 1px solid #99a2a8;
    text-align: center;
    transition: all 300ms linear 0s;
    color: #5f6771;
}
.multi_step_form
    #msform
    fieldset
    .input-group
    .custom-file
    .custom-file-label
    i {
    font-size: 20px;
    padding-right: 10px;
}
.multi_step_form
    #msform
    fieldset
    .input-group
    .custom-file
    .custom-file-label:hover,
.multi_step_form
    #msform
    fieldset
    .input-group
    .custom-file
    .custom-file-label:focus {
    background: #5cb85c;
    border-color: #5cb85c;
    color: #fff;
}
.multi_step_form #msform fieldset .input-group .custom-file input {
    display: none;
}
.multi_step_form #msform fieldset .file_added {
    text-align: left;
    padding-left: 190px;
    padding-bottom: 60px;
}
.multi_step_form #msform fieldset .file_added li {
    font: 400 15px/28px "Roboto", sans-serif;
    color: #5f6771;
}
.multi_step_form #msform fieldset .file_added li a {
    color: #5cb85c;
    font-weight: 500;
    display: inline-block;
    position: relative;
    padding-left: 15px;
}
.multi_step_form #msform fieldset .file_added li a i {
    font-size: 22px;
    padding-right: 8px;
    position: absolute;
    left: 0;
    transform: rotate(20deg);
}
.multi_step_form #msform #progressbar {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    height: 100%;
}

.multi_step_form #msform #progressbar.cancelled li {
    width: 100%;
}

.multi_step_form #msform #progressbar li {
    list-style-type: none;
    color: #99a2a8;
    font-size: 9px;
    width: calc(100% / 4);
    float: left;
    position: relative;
    font: 500 13px/1 "Roboto", sans-serif;
}

.multi_step_form #msform #progressbar.cancelled li:nth-child(1):before {
    content: "\f36e";
}

.multi_step_form #msform #progressbar li:nth-child(2):before {
    content: "\f120";
}
.multi_step_form #msform #progressbar li:nth-child(3):before {
    content: "\f36f";
}
.multi_step_form #msform #progressbar li:nth-child(4):before {
    content: "\f38e";
}
.multi_step_form #msform #progressbar li:nth-child(5):before {
    content: "\f12e";
}
.multi_step_form #msform #progressbar li:nth-child(6):before {
    content: "\f374";
}
.multi_step_form #msform #progressbar li:nth-child(7):before {
    content: "\f119";
}
.multi_step_form #msform #progressbar li:before {
    content: "\f370";
    font: normal normal normal 30px/50px Ionicons;
    width: 50px;
    height: 50px;
    line-height: 50px;
    display: block;
    background: #eaf0f4;
    border-radius: 50%;
    margin: 0 auto 10px auto;
}
.multi_step_form #msform #progressbar li:after {
    content: "";
    width: 100%;
    height: 10px;
    background: #eaf0f4;
    position: absolute;
    left: -50%;
    top: 21px;
    z-index: -1;
}
.multi_step_form #msform #progressbar li:last-child:after {
    width: 150%;
}
.multi_step_form #msform #progressbar li.active {
    color: #5cb85c;
}

.multi_step_form #msform #progressbar.cancelled li.active {
    color: #ff0000;
}

.multi_step_form #msform #progressbar.cancelled li.active:after,
.multi_step_form #msform #progressbar.cancelled li.active:before {
    background: #ff0000;
}

.multi_step_form #msform #progressbar li.active:before,
.multi_step_form #msform #progressbar li.active:after {
    background: #5cb85c;
    color: white;
}
.multi_step_form #msform .action-button {
    background: #5cb85c;
    color: white;
    border: 0 none;
    border-radius: 5px;
    cursor: pointer;
    min-width: 130px;
    font: 700 14px/40px "Roboto", sans-serif;
    border: 1px solid #5cb85c;
    margin: 0 5px;
    text-transform: uppercase;
    display: inline-block;
}
.multi_step_form #msform .action-button:hover,
.multi_step_form #msform .action-button:focus {
    background: #405867;
    border-color: #405867;
}
.multi_step_form #msform .previous_button {
    background: transparent;
    color: #99a2a8;
    border-color: #99a2a8;
}
.multi_step_form #msform .previous_button:hover,
.multi_step_form #msform .previous_button:focus {
    background: #405867;
    border-color: #405867;
    color: #fff;
}

@media (max-width: 576px) {
    .multi_step_form #msform {
        overflow-x: hidden;
    }

    .multi_step_form #msform #progressbar {
        margin-bottom: 0px;
        height: 100%;
        overflow-x: auto;
        width: 356px;
    }
}
