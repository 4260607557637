.table-mini-cart .product-title{
    max-width: 250px;
}

.table-mini-cart .product-col .product-image-container{
    width: 100px;
}

.order-summary h5{
    margin-bottom: 0;
    text-align: right;
}

@media(max-width: 1200px){
    .table-mini-cart .product-title{
        max-width: 200px;
    }
}

@media(max-width: 1024px){
    .table-mini-cart .product-title{
        max-width: 100px;
    }
}

@media(max-width: 768px){
    .table-mini-cart .product-title{
        max-width: 250px;
    }
}