.Overlay {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.75);
}

.pageModal {
  z-index: 999;
}

.pageModal:focus {
  outline: 0px solid transparent;
}

/* Inner Content Modal */
.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.modal-box-body {
  padding: 5rem 2rem 2rem 2rem;
  background-color: #ffffff;
}

button.closeModalBtn {
  position: absolute;
  right: 0.5rem;
  color: #000000;
  background: transparent;
  border: none;
  top: 0.8rem;
  font-size: 2.2rem;
  cursor: pointer;
}

@media (max-width: 576px) {
  button.closeModalBtn {
    right: 0.5rem;
  }
}
