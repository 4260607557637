.author-details-img img {
    width: 80%;
    border-radius: 50%;
}

.author-bio ul{
    list-style-type: disc;
}

.author-desc h3{
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #ddd;
}

@media (max-width: 568px) {
    .author-details-img img {
        width: 70%;
    }

    .author-details-img{
        margin-bottom: 2rem;
    }

    .author-desc h3{
        text-align: center;
    }

}