ul.flex-header{
    display: flex;
    justify-content: space-between;
}

ul.flex-header li.view-all-btn{
    margin-top: 1.2rem;
}

ul.flex-header li.view-all-btn a{
    font-size: 1.5rem;
    font-weight: bold;
    color: #fff;
    background-color: #282D3B;
    padding: 0.5rem 1rem;
}