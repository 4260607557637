ul.footer-links li {
    position: relative;
}

ul.footer-links li a::before {
    content: "⁕";
    font-weight: 700;
    position: absolute;
    font-size: 1.8rem;
    left: 0;
    top: -3px;
}

ul.footer-links li a {
    padding-left: 1.5rem;
}

ul.footer-links li a:hover {
    text-decoration: underline;
}
