.product-highlights{
    font-size: 1.5rem;
}

.product-desc-content ul {
    list-style-type: disc;
}

.product-highlights li {
    margin-bottom: 0.2rem;
}

.preview-modal-wrap{
    text-align: center;
}

img.image-gallery-image{
    max-width: 80% !important;
    margin: 0 auto;
}

.author-name{
    display:flex; 
    align-items: center; 
    margin-left: 1rem; 
    flex: 1 1 0%;
}