img.view-all-cats {
    width: 73%;
}

img.catgeory-img-index {
    border-radius: 0;
}

.carousel-caption {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.carousel-caption .caption-head,
.carousel-caption .caption-desc {
    text-shadow: 1px 1px #000000;
}

.slick-dots {
    position: absolute;
    bottom: 15%;
    left: -45%;
}

.slick-dots li {
    background-color: #ffffff;
    height: 1.2rem;
    width: 0.37rem;
    transition-duration: 0.5s;
}

.slick-dots li.slick-active {
    color: #ffffff;
    height: 1.8rem;
    background-color: rgb(8, 255, 140);
}

.slick-dots li button::before {
    content: none;
}

/* Carousel Arrows */

.main-slide .carousel-nav-btn {
    background-color: #282d3b;
    padding: 1rem 0.7rem;
    border: none;
    width: max-content;
}

.main-slide .slick-arrow {
    display: block;
    position: absolute;
    top: 50%;
    font-size: 2rem;
    height: 20px;
    width: 20px;
    z-index: 99;
    transform: translateY(-50%);
}

.main-slide .next-arrow {
    left: 1rem;
    display: block;
    cursor: pointer;
    color: #ffffff;
}
.main-slide .prev-arrow {
    right: 1rem;
    display: block;
    cursor: pointer;
    color: #ffffff;
}

.main-slide.books-sections .next-arrow {
    left: -0.5rem;
}

.main-slide.books-sections .prev-arrow {
    right: -0.5rem;
}

@media (max-width: 992px) {
    .slick-dots {
        left: -43%;
    }
}

@media (max-width: 756px) {
    .slick-dots {
        left: -40%;
    }
}

@media (max-width: 576px) {
    .slick-dots {
        display: none !important;
    }

    .main-slide .next-arrow {
        left: 0.5rem;
    }
    .main-slide .prev-arrow {
        right: 0.5rem;
    }

    .carousel-caption {
        text-align: center;
    }

    .main-slide.books-sections .next-arrow {
        left: -0.5rem;
    }

    .main-slide.books-sections .prev-arrow {
        right: -0.5rem;
    }
}
